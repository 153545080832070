import _ from 'lodash';
import React, { useCallback } from 'react';
import { Lang } from 'shared/constants';
import { Input, Loader } from '../../../components/core';
import styles from './users-management.module.scss';
import { Table, notification } from 'antd';
import { Button } from '../../../components/design-system';
import {
  camelCaseToWords,
  normalizedContains,
  removeDiacritics,
  sortByAlphabet,
} from 'shared/utils';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { ScreenTitle } from '../../../components/design-system/titles';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { UserDetailsComponent } from '../../../components/user-details';
import { useHistory, useLocation } from 'react-router';
import { INTERNAL_ROUTING, ROUTES } from 'shared/constants/routes';
import { DeleteUserConfirm } from './delete-user-confirm';
import { TransferOwner } from './transfer-owner';
import classNames from 'classnames';

const PAGE_SIZE = 10;

const UserManagementPageComponent = React.memo(function UserManagementPageComponent({
  lang,
  users,
  fetchUsers,
  deleteUser: deleteUserAction,
}) {
  const history = useHistory();
  const { search } = useLocation();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [deleteUserEntity, setDeleteUserEntity] = React.useState(null);
  const [transferOwnership, setTransferOwnership] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const tableRef = React.useRef({ pagination: { current: 1 } });
  const [api, contextHolder] = notification.useNotification();

  const openDetails = useCallback(
    id => history.push(`${ROUTES.USERS_MANAGEMENT}?${INTERNAL_ROUTING.QUERY_PARAMS.USERS}=${id}`),
    [history],
  );

  const closeDetails = useCallback(() => {
    history.push(ROUTES.USERS_MANAGEMENT);
    // needed to close when editing multiple users
    if (userDetails) setUserDetails(null);
  }, [history, userDetails]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get(INTERNAL_ROUTING.QUERY_PARAMS.USERS);
    if (id) {
      setUserDetails(users?.find(user => user.id === id));
    } else {
      setUserDetails(null);
    }
  }, [search, users]);

  const onSearchTermChanged = e => setSearchTerm(removeDiacritics(e?.target?.value));
  const userProfileOptions = {
    ...useSelector(getLang('PROFILE_OPTIONS')),
    ...useSelector(getLang('TAGS')),
  };

  const openNotification = useCallback(
    ({ message, type = 'success' }) => {
      api[type]({
        message,
        placement: 'bottomRight',
        duration: 10,
      });
    },
    [api],
  );

  const onDeleteUser = useCallback(user => setDeleteUserEntity(user), []);
  const deleteUser = useCallback(
    async ({ ownerId }) => {
      const res = await deleteUserAction({ id: deleteUserEntity.id, ownerId });

      if (!res?.error) {
        openNotification({ message: lang.NOTIFICATION_DELETE_SUCCESS });
        setDeleteUserEntity(null);
        closeDetails();
      }
    },
    [deleteUserEntity, deleteUserAction, openNotification, lang, closeDetails],
  );

  const cancelDeleteUser = useCallback(() => setDeleteUserEntity(null), []);

  // Transfer ownership
  const cancelTransferOwnership = useCallback(() => setTransferOwnership(null), []);

  const onTransferOwnership = useCallback(
    ({ user, action, dismiss }) => {
      setTransferOwnership({
        user,
        action,
        dismiss: () => {
          dismiss();
          cancelTransferOwnership();
        },
      });
    },
    [cancelTransferOwnership],
  );

  const columns = [
    {
      title: lang.LAST_NAME,
      dataIndex: 'lastName',
      sorter: {
        compare: (a, b) => sortByAlphabet(a.lastName, b.lastName),
      },
      render: value => value ?? '-',
      defaultSortOrder: 'ascend',
    },
    {
      title: lang.FIRST_NAME,
      dataIndex: 'firstName',
      sorter: {
        compare: (a, b) => sortByAlphabet(a.firstName, b.firstName),
      },
      render: value => value ?? '-',
    },
    {
      title: lang.EMAIL,
      dataIndex: 'email',
      sorter: {
        compare: (a, b) => sortByAlphabet(a.email, b.email),
      },
      render: value => value ?? '-',
    },
    {
      title: lang.PROFESSION,
      dataIndex: 'profession',
      ellipsis: true,
      sorter: {
        compare: (a, b) => sortByAlphabet(a.profession, b.profession),
      },
      render: value => userProfileOptions[value] ?? '-',
    },
    {
      title: lang.ROLE,
      dataIndex: 'role',
      ellipsis: true,
      sorter: {
        compare: (a, b) => sortByAlphabet(a.role, b.role),
      },
      render: value => userProfileOptions[value] ?? '-',
    },
    {
      title: lang.ROTATION,
      dataIndex: 'rotation',
      ellipsis: true,
      sorter: {
        compare: (a, b) => sortByAlphabet(a.rotation, b.rotation),
      },
      render: value => userProfileOptions[value] ?? '-',
    },
    {
      title: lang.LEVEL_OF_TRAINING,
      dataIndex: 'levelOfTraining',
      ellipsis: true,
      sorter: {
        compare: (a, b) => sortByAlphabet(a.levelOfTraining, b.levelOfTraining),
      },
      render: value => userProfileOptions[value] ?? '-',
    },
    {
      title: lang.PERMISSIONS,
      dataIndex: 'permissions',
      sorter: {
        compare: (a, b) => sortByAlphabet(a.permissions, b.permissions),
      },
      render: value => camelCaseToWords(value),
    },
  ];

  const filterAction = ({ email, firstName, lastName }) =>
    normalizedContains(email, searchTerm) ||
    normalizedContains(firstName, searchTerm) ||
    normalizedContains(lastName, searchTerm);

  React.useEffect(() => {
    if (_.isNull(users)) fetchUsers();
  }, [users, fetchUsers]);

  const renderPageInfo = () => {
    const currentPage = tableRef.current?.pagination?.current ?? 1;
    return `Showing ${1 + currentPage * PAGE_SIZE - PAGE_SIZE}-${
      currentPage * PAGE_SIZE > users?.length ? users?.length : currentPage * PAGE_SIZE
    } from ${users.length} users`;
  };

  const onSelectRows = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = { onChange: onSelectRows, selectedRowKeys };

  const renderList = React.useCallback(() => {
    if (_.isNull(users)) {
      return <Loader />;
    }

    return (
      <Table
        pagination={{
          showSizeChanger: false,
          pageSize: PAGE_SIZE,
        }}
        columns={columns}
        dataSource={users.filter(filterAction).map(user => ({
          ...user,
          firstName: user?.firstName ?? user?.email,
          ...user.professional,
        }))}
        onRow={record => ({
          onClick: e => {
            e.stopPropagation();
            openDetails(record?.id);
          },
        })}
        rowClassName={styles.row}
        rowKey={record => `row-${record.id}`}
        onChange={pagination => {
          tableRef.current.pagination = pagination;
        }}
        defaultSortField="lastName"
        footer={renderPageInfo}
        rowSelection={rowSelection}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, searchTerm, selectedRowKeys]);

  return (
    <>
      {contextHolder}
      <UserDetailsComponent
        user={userDetails}
        close={closeDetails}
        open={!!userDetails}
        lang={lang}
        onDeleteUser={onDeleteUser}
        onTransferOwnership={onTransferOwnership}
        openNotification={openNotification}
      />
      <div className={classNames(['new-design-system', styles.root])}>
        <div className={styles.header}>
          <div className={styles.title}>
            <ScreenTitle
              title={`${lang.HEADER} ${users?.length > 0 ? `(${users?.length})` : ''}`}
            />

            <Button
              className={styles.button}
              type="primary"
              ghost
              icon={<PlusCircleTwoTone />}
              onClick={() => setUserDetails({})}
            >
              {lang.ADD_NEW_USER}
            </Button>
          </div>
          <div className={styles.search}>
            {selectedRowKeys.length > 0 && (
              <Button
                className={styles.button}
                ghost
                type="primary"
                onClick={() => {
                  if (selectedRows.length === 1) {
                    openDetails(selectedRows[0].id);
                  }
                  setUserDetails(selectedRows);
                }}
              >
                {lang.EDIT_USER.replace('{number}', selectedRowKeys.length ?? '')}
              </Button>
            )}

            <Input
              className={styles.input}
              onChange={onSearchTermChanged}
              placeholder={lang.SEARCH_PLACEHOLDER}
            />
          </div>
        </div>

        {renderList()}
      </div>
      {deleteUserEntity && (
        <DeleteUserConfirm
          user={deleteUserEntity}
          onConfirm={deleteUser}
          onDismiss={cancelDeleteUser}
        />
      )}

      {transferOwnership && (
        <TransferOwner
          onConfirm={transferOwnership.action}
          user={transferOwnership.user}
          onDismiss={transferOwnership.dismiss}
        />
      )}
    </>
  );
});

UserManagementPageComponent.defaultProps = {
  lang: Lang.USERS_MANAGEMENT,
  users: null,
  fetchUsers: () => {},
};

export { UserManagementPageComponent };
